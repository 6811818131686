<template>
    <div class="pa-0 ma-0">
        <v-card flat style="background-color: var(--v-background-base) !important" class="pa-0 ma-0">
            <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55" class="pa-0 ma-0">
                <v-toolbar-title>
                    <b class="mr-3">Stock Management: Files</b>
                </v-toolbar-title>
            </v-toolbar>

            <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                    'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                    'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
                }">
                <v-card-text :style="{
                        height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                        'overflow-y': 'auto',
                    }">
                    <v-tabs show-arrows v-model="activeTab" height="40px" class="mb-1 pb-1 mt-0 pt-0">
                        <v-tab v-for="tab in tabs" :key="tab.value" :href="`#${tab.name}`" @click="activeTab != `#${tab.value}` ? $router.push({ hash: `#${tab.value}` }) : ''">
                            {{ tab.name }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="activeTab">
                        <!-- <v-tab-item value="Upload">
                            <Upload />
                        </v-tab-item> -->
                        <v-tab-item value="Stock Files">
                            <PO />
                        </v-tab-item>
                        <v-tab-item value="CSV Mapping">
                            <CSVMapping />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-card>
    </div>
</template>
<script>
import PO from '@/components/Stock/PO.vue'
// import Upload from '@/components/Stock/Upload.vue'
import CSVMapping from '@/components/Stock/CSVMapping.vue'
export default {
    components: {
        CSVMapping,
        PO,
        // Upload
    },
    data: () => ({
        activeTab: 0,
        tabs: [
      { name: "Stock Files", value: "stockFiles" },
      { name: "CSV Mapping", value: "cSVMapping" },
    ],
    }),
    mounted() {
        if (window.location.hash) {
            let component = decodeURI(window.location.hash).split('#')[1];
            this.activeTab = component
        } else {
            this.$router.push({
                hash: '#stockFiles'
            })
        }
    },
}
</script>